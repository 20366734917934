import resize from 'iframe-resizer/js/iframeResizer.contentWindow.js'

import {
  PublicSimplePageResponse,
  PublicWidget
} from '@/service/__generated-api'
import { getCORSsafeOrigin } from '@/utils/get-cors-safe-origin'

export function usePreviewModule() {
  const root = ref<HTMLElement>()
  const newWidgetYpos = ref(-1)
  const newWidgetIndex = ref<number>(-1)
  const preview = ref<boolean>(false)
  const page = ref<PublicSimplePageResponse>()
  const dataSending = ref(false)
  const scale = ref(1)

  let pingerIntervalId: NodeJS.Timeout
  resize // eslint-disable-line

  watchEffect(() => {
    if (preview.value) {
      clearInterval(pingerIntervalId!)
    }
  })

  const addWidget = (index: number) => {
    newWidgetIndex.value = index
  }

  const newWidget = (index: number) => {
    postMessageToParent({ type: 'newWidget', index })
  }

  const removeWidget = (index: number) => {
    postMessageToParent({ type: 'removeWidget', index })
  }

  const moveUpWidget = (index: number) => {
    postMessageToParent({ type: 'moveUpWidget', index })
  }

  const moveDownWidget = (index: number) => {
    postMessageToParent({ type: 'moveDownWidget', index })
  }

  const editWidget = (index: number) => {
    postMessageToParent({ type: 'editWidget', index })
  }

  const editHeader = () => {
    postMessageToParent({ type: 'editHeader' })
  }

  const setNewWidgetTopPos = (yPos: number) => {
    newWidgetYpos.value = root.value
      ? root.value.getBoundingClientRect().height * yPos
      : 0
  }

  const listenToParentPage = (res: MessageEvent) => {
    const { data } = res
    const { type } = data
    if (!type) {
      return
    }
    if (type === 'newWidgetPos') {
      setNewWidgetTopPos(data.yPos)
    }
    if (type === 'scale') {
      scale.value = data.scale
    }
    if (type === 'preview') {
      dataSending.value = false
      preview.value = data.preview
      page.value = data.page || {}
    }
    if (type === 'newWidgetData') {
      const index = newWidgetIndex.value
      postMessageToParent({
        type: 'addWidget',
        index,
        widgetData: data.widgetData
      })
    }
  }

  type PingMessage = { type: 'ping' }

  type WidgetActionMessage = {
    type:
      | 'addWidget'
      | 'newWidget'
      | 'removeWidget'
      | 'moveUpWidget'
      | 'moveDownWidget'
      | 'editWidget'
    index: number
    widgetData?: PublicWidget
  }

  type HeaderActionMessage = {
    type: 'editHeader'
    index?: number
    widgetData?: PublicWidget
  }

  type ParentMessage = PingMessage | WidgetActionMessage | HeaderActionMessage

  const postMessageToParent = (payload: ParentMessage) => {
    dataSending.value = true
    window.parent.postMessage(payload, getCORSsafeOrigin() as string)
  }

  onMounted(() => {
    window.addEventListener('message', listenToParentPage)
    pingerIntervalId = setInterval(
      () => postMessageToParent({ type: 'ping' }),
      50
    )
  })

  onUnmounted(() => {
    window.removeEventListener('message', listenToParentPage)
  })

  const {
    query: { interfaceLanguage }
  } = useRoute()

  const locale = computed(() =>
    typeof interfaceLanguage === 'string' ? interfaceLanguage : 'en'
  )

  return {
    root,
    newWidgetYpos,
    preview,
    page,
    dataSending,
    scale,
    addWidget,
    newWidget,
    removeWidget,
    moveUpWidget,
    moveDownWidget,
    editWidget,
    editHeader,
    locale
  }
}
